import React from 'react'
import {Container, TextField} from "@mui/material";
import SiNoForm from "./FormDomande/SiNoForm";
import QuizForm from "./FormDomande/QuizForm";

import PercentageForm from "./FormDomande/PercentageForm";

export default function AddQueueElementForm({type, handleSetDomanda, handleSetSoglia, handleSetRisposte, risposte, handleSetCorretta,corretta}) {

    function setDomanda(event) {
        handleSetDomanda(event.target.value)
    }

    return(
        <Container>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Question"
                type="text"
                fullWidth
                variant="standard"
                onChange={setDomanda}
            />
            {(() => {
                switch (type) {
                    case 'clickTo':
                        return <PercentageForm handleSetSoglia={handleSetSoglia}/>
                    case 'quiz':
                        return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} handleSetCorretta={handleSetCorretta} corr={corretta}/>
                    case 'poll':
                        return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} poll={true}/>
                    default:
                        return null
                }
            })()}
        </Container>
    )
}