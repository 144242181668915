import React, {useState} from "react";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    TextField,
    IconButton
} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import {AddCircle, Edit} from "@mui/icons-material";

function AddButton({event, handleSetEditingButton}) {
    const [type, setType] = useState('')
    const [title, setTitle] = useState('')

    const handleTypeChange = (event) => {
        setType(event.target.value)
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    async function addButton() {
        if(title!=='' && type!==''){
            await firestore.collection('users/plesh/events/' + event.id + '/buttons').add(
                {
                    active: false,
                    title: title,
                    type: type,
                    timestamp: new Date().getTime()
                }
            )
        }
    }

    return (
        <Grid container spacing={2} alignItems={"flex-end"} sx={{mb:2, display: event.playing? 'none' : 'flex'}} >
            <Grid item xs={5}>
                <FormControl variant="standard" sx={{minWidth: '100%'}}>
                    <TextField value={title} onChange={handleTitleChange} id="title" label="Title"
                               variant="standard"/>
                </FormControl>
            </Grid>
            <Grid item xs={5}>
                <FormControl variant="standard" sx={{minWidth: '100%'}}>
                    <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={type}
                        onChange={handleTypeChange}
                        label="Type"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={'vote'}>Vote</MenuItem>
                        <MenuItem value={'click'}>Click</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <IconButton disabled={title==='' || type===''} onClick={addButton} size={"large"} color={"primary"}>
                    <AddCircle/>
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={handleSetEditingButton} size={"large"} color={"primary"}>
                    <Edit/>
                </IconButton>
            </Grid>
        </Grid>
    )
}


export default AddButton