import React from 'react'
import {Box, Grid} from "@mui/material";
import GraficoLink from "./Grafici/GraficoLink";
import GraficoSingolo from "./Grafici/GraficoSingolo";
import GraficoPiuMeno from "./Grafici/GraficoPiuMeno";

export default function ControlGrafici({buttons, buttonsValue}) {

    return(
        <Grid container sx={{width:'100%', height: '100%'}} style={{textTransform:'uppercase'}} alignContent={"center"} alignItems={"center"} justifyContent={"center"} spacing={1}>
            {/*<Grid item xs={12}>
                <GraficoLink buttons={buttons} buttonsValue={buttonsValue}/>
            </Grid>
                <Grid item xs={12}>
                <GraficoPiuMeno buttons={buttons} buttonsValue={buttonsValue}/>
                </Grid>*/}
            <Grid item xs={12}>
                <GraficoSingolo buttons={buttons} buttonsValue={buttonsValue}/>
            </Grid>
        </Grid>
    )
}