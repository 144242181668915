import React, {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {Box, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import AddQueueElementForm from "./AddQueueElementForm";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PercentageForm from "./FormDomande/PercentageForm";
import QuizForm from "./FormDomande/QuizForm";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";

export default function EditQueueElement({open, handleSetOpen, element}) {
    const {id} = useParams()
    const [domanda, setDomanda] = useState('')
    const [risposte, setRisposte] = useState([])
    const [soglia, setSoglia] = useState(null)
    const [corretta, setCorretta] = useState('')

    useEffect(() => {
        setDomanda(element.domanda)
        setRisposte(element.risposte)
        setSoglia(element.soglia)
        setCorretta(element.corretta)
    }, [element])

    function handleSetDomanda(event) {
        setDomanda(event.target.value)
    }

    const handleSetRisposte = (risposte) => {
        setRisposte([...risposte])
    }

    const handleSetSoglia = (soglia) => {
        setSoglia(soglia)
    }

    const handleSetCorretta = (corretta) => {
        setCorretta(corretta)
    }

    function handleEdit() {
        let updated = element
        updated.domanda = domanda
        if (risposte) {updated.risposte = risposte}
        if (soglia) {updated.soglia = soglia}
        firestore.collection('users/plesh/events/' + id + '/queue').doc(element.id)
            .set({...updated}, {merge: true})
            .then(r => handleSetOpen())
    }

    return (
        <Dialog open={open} onClose={handleSetOpen} fullWidth>
            <DialogTitle>Edit queue element</DialogTitle>
            <Container>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Question"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={domanda}
                    onChange={handleSetDomanda}
                />
                {(() => {
                    switch (element.tipo) {
                        case 'clickTo':
                            return <PercentageForm handleSetSoglia={handleSetSoglia} soglia={soglia}/>
                        case 'quiz':
                            return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte}
                                             handleSetCorretta={handleSetCorretta} corr={corretta}/>
                        case 'poll':
                            return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} poll={true}/>
                        default:
                            return null
                    }
                })()}
                <DialogActions>
                    <Button onClick={handleSetOpen}>Cancel</Button>
                    <Button onClick={handleEdit}>Save</Button>
                </DialogActions>
            </Container>
        </Dialog>
    )
}