import React, {useState} from "react";
import {Button, Card, CardContent, IconButton, Stack, Typography} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QrButton from "./QrButton";
import RemoveEventButton from "./RemoveEventButton";
import {Edit, Stop} from "@mui/icons-material";
import {firestore} from "../firebase/clientApp";
import EditEvent from "./EditEvent";


function Event({event, handleRemoveEvent}) {
    const [open, setOpen]=useState(false)

    function pauseEvent() {
        firestore.collection('users/plesh/events').doc(event.id).set(
            {
                playing: !event.playing
            }, {merge: true}
        ).then(r => console.log('Updated'))
    }

    const handleSetOpen = () => {
        let o = !open
        setOpen(o)
    }

    return (
        <Card sx={{m: 2}}>
            <Stack sx={{p: 1}} direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    <Button href={`/events/${event.id}`} size={"large"}>{event.title}</Button>
                    {
                        event.playing ? '' :
                            <IconButton size={"large"} onClick={handleSetOpen}>
                                <Edit fontSize={"inherit"}/>
                            </IconButton>
                    }
                </Stack>
                <Stack direction={"row"}>
                    <RemoveEventButton event={event} handleRemoveEvent={handleRemoveEvent}/>
                    <QrButton event={event}/>
                    <IconButton aria-label="play" size={"large"} onClick={pauseEvent}>
                        {
                            event.playing ?
                                <Stop fontSize={"inherit"}/> :
                                <PlayArrowIcon fontSize={"inherit"}/>
                        }
                    </IconButton>
                </Stack>
            </Stack>
            <EditEvent event={event} handleSetOpen={handleSetOpen} open={open}/>
        </Card>
    )

}

export default Event