import React, {useEffect, useState} from "react";
import {Stack, Typography} from "@mui/material";

export default function PercentageVisual({elementPlaying,result}) {
    const [soglia, setSoglia] = useState(0)
    const [percentuale, setPercentuale] = useState(0)

    useEffect(()=> {
        setSoglia(elementPlaying.soglia)
        setPercentuale(result.data ? result.data.yes : 0)
    },[result])

    return (
        <div style={{width: '100%', height: '100%', textAlign: 'center', position: 'relative'}}>
            <Typography variant={"h3"} component={"div"} sx={{py: 3}}>
                Have clicked
            </Typography>
            <div style={{position:'relative', width:'70%', margin:'auto'}}>
                <Stack direction={"column"} alignItems={"center"} style={{position:'absolute', left:`calc(${soglia}% - 30px)`}}>
                    <div style={{height: '120px', borderLeft: '8px solid #D32F2F', margin:'auto'}}/>
                    <Typography variant={"h4"} component={"p"} sx={{py: 3}} style={{top:'100px'}}>
                        {soglia}%
                    </Typography>
                </Stack>

            </div>
            <div style={{
                width: '70%',
                borderRadius: '20px',
                border: '3px solid #FFEB3B',
                height: '80px',
                margin: 'auto',
                top: '100px',
                marginTop:'1rem'
            }}>
                <Stack direction={"row"} width={'100%'} height={'100%'}>
                    <div style={{
                        width: percentuale + '%',
                        maxWidth:'100%',
                        height: '100%',
                        backgroundColor: '#FFEB3B',
                        borderRadius: '10px',
                        transition: '1s ease',
                    }}>

                    </div>
                    <Typography component={"div"} variant={'h4'} sx={{p:2, position:'absolute', color: '#000'}}>
                        {percentuale}%
                    </Typography>
                </Stack>
            </div>
            <div style={{width:'70%', margin:'auto', textAlign:'left'}}>

            </div>
        </div>
    )
}