import React, {useState} from "react";
import LinkButtonsSelect from "./LinkButtonsSelect";
import AddButton from "./AddButton";
import IconButton from "@mui/material/IconButton";
import {AddCircle} from "@mui/icons-material";
import {Button, Stack, Typography} from "@mui/material";

export default function LinkButton({buttons, buttonsLink,handleAddButtonsLink, eventId}){

    function addNewLink() {
        handleAddButtonsLink({lista:[]})
    }

    return(
        <Stack direction={"column"} alignItems={'flex-start'}>
            <Typography variant={'h6'}>Link button</Typography>
            {buttonsLink.map((l,i) =>
                    <LinkButtonsSelect key={i} i={i} b={buttons} link={l} eventId={eventId}/>
               )
            }
            <IconButton  onClick={addNewLink}>
                <AddCircle/>
            </IconButton>
        </Stack>
    )
}