import {createTheme} from "@mui/material";

const siNo1='#3a4de4'
const siNo2='#00d8a1'
const sentiment1='#3a4de4'
const sentiment2='#00d8a1'
const poll1='#3a4de4'
const poll2='#00d8a1'
const wordcloud1='#3a4de4'
const wordcloud2='#00d8a1'
const quiz1='#3a4de4'
const quiz2='#00d8a1'

export const pleshDark = createTheme({
    typography: {
        fontFamily: ['Proto Grotesk']
    },

    components:{
        MuiAppBar: {
            styleOverrides:{
                root:{
                    background: 'linear-gradient(90deg, rgba(21,26,55,1) 0%, rgba(10,2,36,1) 100%)',
                    borderBottom: '2px solid #151a37',
                    borderRadius:'0'
                }
            }
        },
        MuiPaper: {
            styleOverrides:{
                root:{
                    background: 'linear-gradient(167deg, rgba(10,2,36,1) 0%, rgba(21,26,55,1) 60%)',
                    border: '2px solid #151a37',
                    borderRadius:'2rem'
                }
            }
        },
        MuiButton:{
            styleOverrides: {
                contained: {
                    borderRadius:'1rem',
                    color:'#f1ebe9',
                    backgroundColor: 'rgba(234,77,149,1)',
                    background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)'
                }
            }
        }
    },

    palette: {
        mode: 'dark',
        primary: {
            main: '#e6fc69',
        },
        secondary: {
            main: '#263238',
        },
        background: {
            paper: '#455a64',
        },
        accent: {
            main: '#e6fc69',
        },
        text: {
            primary: '#eceff1',
        },
    },
    customColors: {
        clickTo: {
            main: '#FFEB3B',
        },
        wordcloud: {
            main: 'linear-gradient(90deg, '+wordcloud1+' 0%, '+wordcloud2+' 100%)'
        },
        quiz: {
            main: 'linear-gradient(90deg, '+quiz1+' 0%, '+quiz2+' 100%)'
        },
        siNo: {
            main: 'linear-gradient(90deg, '+siNo1+' 0%, '+siNo2+' 100%)'
        },
        poll: {
            main: 'linear-gradient(90deg, '+poll1+' 0%, '+poll2+' 100%)'
        },
        sentiment: {
            main: 'linear-gradient(90deg, '+sentiment1+' 0%, '+sentiment2+' 100%)'
        }
    }
});
