import React, {useEffect, useState} from "react";
import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const options = {
    colors: ['#f1ebe9'],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "roboto",
    fontSizes: [25, 100],
    fontStyle: "normal",
    fontWeight: "bold",
    padding: 7,
    rotations: 3,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
};

export default function WordcloudVisual({result}){
    const [parole, setParole] = useState([])

    useEffect(() => {
        Array.isArray(result.data) && setParole(result.data.slice(0,80))
    }, [result])
    return(
        <ReactWordcloud words={parole} options={options}/>
    )
}
