import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";

export default function PercentageForm({handleSetSoglia, soglia}){
    const [limit, setLimit] = useState(null)

    useEffect(()=>{
        soglia ? setLimit(soglia) : setLimit(null)
    }, [soglia])

    function handleSetLimit (event) {
        setLimit(event.target.value < 0 ? 0 : event.target.value > 100 ? 100 : event.target.value)
        handleSetSoglia(event.target.value < 0 ? 0 : event.target.value > 100 ? 100 : event.target.value)
    }

    return(
        <div>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Limit"
                type="number"
                fullWidth
                variant="standard"
                value={limit}
                onChange={handleSetLimit}
            />
        </div>
    )
}