import React, {useEffect, useState} from "react";
import {Button, CircularProgress, FormControl, Grid, Input, styled, TextField, Typography} from "@mui/material";

import {useParams} from "react-router-dom";
import {db} from "../firebase/clientApp";


const options = {
    colors: ['#f1ebe9'],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "roboto",
    fontSizes: [10, 40],
    fontStyle: "normal",
    fontWeight: "bold",
    padding: 0,
    rotations: 3,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
};

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0288D1',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0288D1',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f1ebe9',
        },
        '&:hover fieldset': {
            borderColor: '#f1ebe9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0288D1',
        },
    },
});

export default function ClassificaGiudizio(){
    const {id} = useParams()
    const [words, setWords] = useState([])

    useEffect(()=>{
        getWcData()
    },[])

    function getWcData (){
        db.ref('users/plesh/events/' + id + '/queue/OQLx5eM0heQlzFFX1KXT').on('value', (s) => {
            let res = s.val();
            const bv = []
            Object.entries(res.answers).forEach((doc) => {
                const [key, value] = doc;
                bv.push({text: key, value: value})
            })
            let o = bv.sort((a, b) => {
                return b.value - a.value;
            });
            setWords(o.slice(0,10));
        })
    }


    const ItemList = () => (
        words.length > 0 && words.map((o,i) =>
            <li key={i} style={{fontSize: '60px'}}>{i+1}. {o.text}</li>
        )
    )

    return(
        <Grid direction={'column'} sx={{position:'relative'}} container justifyContent={"center"} alignItems={"center"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            <Grid item style={{overflow:'scroll'}} justifyContent={"center"} alignItems={"center"}>
                <ul style={{listStyleType: 'none', padding:'0', textAlign:'left'}}>
                    <ItemList/>
                </ul>
            </Grid>

        </Grid>
    )
}