import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {AddCircle, RemoveCircle} from "@mui/icons-material";

export function InputRisposta({i, handleUpdateRisposta, handleRemoveRisposte, risp, corr, handleSetCorretta, poll}) {
    const [risposta, setRisposta] = useState('')
    const [corretta, setCorretta] = useState('')
    const [checked, setChecked] = useState(false)

    useEffect(()=> setRisposta(risp), [risp])
    useEffect(()=> setCorretta(corr), [corr])
    useEffect(() => {
        (corretta === risposta && corretta!=='') ? setChecked(true) : setChecked(false)
        }, [corretta, risposta]
    )

    function handleCheck(event){
        event.target.checked ? handleSetCorretta(risp) : handleSetCorretta('')
    }


    function handleSetRisposta (event) {
        setRisposta(event.target.value)
        let value = event.target.value
        handleUpdateRisposta({i, value})
    }

    return (
        <Grid container alignItems={'center'}>
            <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveRisposte({i})}>
                    <RemoveCircle />
                </IconButton>
            </Grid>
            <Grid item xs={11}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField value={risposta} onChange={handleSetRisposta} label={'Number ' + (i + 1)} variant={"standard"} fullWidth/>
                    </Grid>
                    {!poll && <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox onChange={handleCheck} checked={checked}/>}
                                          label="Correct"/>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default function QuizForm({handleSetRisposte, risp, corr, handleSetCorretta, poll}) {
    const [risposte, updateRisposte] = useState([])

    useEffect(() => {updateRisposte(risp)},[risp])

    function addNewRisposta() {
        let e = ''
        let r = risposte
        let newR = r.concat(e)
        handleSetRisposte(newR)
    }

    function handleUpdateRisposta({i,value}) {
        let r = risposte
        r[i] = value
        handleSetRisposte(r)
    }

    function handleRemoveRisposte({i}) {
        let r = risposte
        r.splice(i,1)
        handleSetRisposte(r)
    }

    return (
        <Box sx={{pt: 3}}>
            <Typography variant={"h5"}>Add answers below</Typography>
            <Stack direction={"column"}>
                {risposte.map((r,i) =>
                    <InputRisposta
                        i={i}
                        risp={risposte[i]}
                        handleUpdateRisposta={handleUpdateRisposta}
                        handleRemoveRisposte={handleRemoveRisposte}
                        handleSetCorretta={handleSetCorretta}
                        corr={corr}
                        poll={poll}
                    />
                )}
            </Stack>
            <IconButton onClick={addNewRisposta}>
                <AddCircle/>
            </IconButton>
        </Box>
    )
}