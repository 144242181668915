import React, {useEffect, useState} from "react";
import {Box, Collapse, IconButton, ListItem, ListItemText} from "@mui/material";
import {Edit, PlayArrow, Stop} from "@mui/icons-material";
import QueuePin from "./QueuePin";
import RemoveQueueElementButton from "./RemoveQueueElementButton";
import EditQueueElement from "./EditQueueElement";

export default function QueueElement({element, handleRemoveElement, handlePlayElement,elementPlaying, playing}) {
    const [play, setPlay] = useState(false)
    const [open, setOpen] =useState(false)

    useEffect(()=> {
        if(element.id === elementPlaying){
            setPlay(true)
        } else {
            setPlay(false)
        }
    },[elementPlaying])

    function updatePlay(){
        handlePlayElement(element.id)
    }

    const handleSetOpen = () => {
        let o= !open
        setOpen(o)
    }

    return(
        <div>
            <ListItem
                secondaryAction={
                playing?
                    <IconButton onClick={updatePlay} edge="end" aria-label="play" size={"large"}>
                        {element.id === elementPlaying? <Stop fontSize={"inherit"}/> : <PlayArrow fontSize={"inherit"}/>}
                    </IconButton> :
                    <IconButton onClick={handleSetOpen}>
                        <Edit/>
                    </IconButton>
                }
            >
                {playing ? <Box height={'48px'}/> : <RemoveQueueElementButton id={element.id} handleRemoveElement={handleRemoveElement}/>}
                <QueuePin tipo={element.tipo}/>
                <ListItemText
                    primary={element.domanda}
                />
            </ListItem>
            <EditQueueElement handleSetOpen={handleSetOpen} element={element} open={open}/>
            {/*<Collapse in={play}>collapse</Collapse>*/}
        </div>

    )
}