import React, {useState} from "react";
import {Chip, useTheme} from "@mui/material";

export default function QueuePin ({tipo}) {
  const theme = useTheme()
  const [content, setContent] = useState(findContent)

  function findContent() {
    let c=''
    switch (tipo){
      case 'siNo':
        c = 'Si/No';
        break
      case 'quiz':
        c = "Quiz"
        break
      case 'poll':
        c = "Poll"
        break
      case 'wordcloud':
        c = "Wordcloud"
        break
      case 'clickTo':
        c = "Click To"
        break
      case 'sentiment':
        c = "Sentiment"
        break
      default:
        break
    }
    return(c)
  }

  return(
      <Chip sx={{mr:2}} label={content} style={{background: theme.customColors[tipo].main, color: tipo === 'poll' ? '#151a37' : '', minWidth:'100px'}}/>
  )
}
