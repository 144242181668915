import React, {useEffect, useRef, useState} from "react";
import {
    Card,
    CardContent,
    Container, FormControlLabel,
    Grid,
    List, Stack, Switch,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import QueueAccordion from "../components/Control/QueueAccordion";
import {firestore, db} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import ControlContent from "../components/Control/ControlContent";
import ControlButton from "../components/Control/ControlButton";
import ControlGrafici from "../components/Control/ControlGrafici";
import Box from "@mui/material/Box";


function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

function Control() {
    const {id, mode} = useParams()
    const [buttons, setButtons] = useState([])
    const [idPlaying, setIdPlaying] = useState('')
    const [buttonsValue, setButtonsValue] = useState([])
    const [grafici, setGrafici] = useState(true)
    const prevIdPlaying = usePrevious(idPlaying)
    const url = 'users/plesh/events/' + id

    useEffect(() => {
        getButtonsValue()
        getButtons()
        getIdPlaying()
    }, [])

    function getButtons() {
        firestore.collection(url + '/buttons').orderBy('timestamp', 'asc').onSnapshot(s => {
            let but = []
            s.forEach(b => {
                    but.push({...b.data(), id: b.id})
                }
            )
            setButtons(but)

        })
    }

    function getButtonsValue() {
        db.ref('users/plesh/events/' + id + '/buttons').on('value', (s) => {
            let res = s.val();
            const bv = []
            Object.entries(res).forEach((doc) => {
                const [key, value] = doc;
                bv.push({...value, id: key})
            })
            setButtonsValue(bv);
        })
    }

    function getIdPlaying() {
        firestore.collection('users/plesh/events').doc(id).onSnapshot(doc => {
            if (doc.data().elementPlaying !== '') {
                setIdPlaying(() => doc.data().elementPlaying)
            } else {
                setIdPlaying(prevIdPlaying)
            }
        })
    }

    const handleSwitchGrafici = () => {
        let g = grafici
        setGrafici(!grafici)
    }

    return (
        <Grid container style={{height: '100vh'}}>
            {(!mode || mode === 'grafici') &&
                <Grid item xs={12} sx={{py: 1}} style={{height: !mode ? '55%' : '100%', overflow: 'scroll'}}>
                    <Container maxWidth={'xxl'} style={{height: '100%', overflow: 'scroll'}}>
                        {!mode && <Card style={{height: '100%'}}>
                            <CardContent style={{height: '100%'}}>
                                {/*<Stack direction={"row"}>
                                <Typography variant={"h4"}>Bottoni</Typography>
                                <FormControlLabel sx={{px: 2}}
                                                  control={<Switch value={grafici} onChange={handleSwitchGrafici}/>}
                                                  label="Grafici"/>
                            </Stack>*/}
                                <ControlGrafici buttonsValue={buttonsValue} buttons={buttons}/>
                            </CardContent>

                        </Card>}
                        {mode === 'grafici' && <Box style={{height: '100%'}}>
                            <ControlGrafici buttonsValue={buttonsValue} buttons={buttons}/>
                        </Box>}
                    </Container>
                </Grid>}
            {(!mode || mode === 'domanda') &&
                <Grid item xs={12} sx={{background:'transparent'}}
                      style={{height: !mode ? '45%' : '100%', borderRadius: '50px 50px 0 0'}}>
                    <ControlContent idPlaying={idPlaying} url={url} eventId={id}/>
                </Grid>}
        </Grid>
    )
}

export default Control