import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {Button, Grid, IconButton} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import {RemoveCircle} from "@mui/icons-material";
import {useEffect} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function LinkButtonsSelect({b, link, eventId}) {
    const theme = useTheme();
    const [selected, setSelected] = React.useState([]);

    useEffect(()=>{
        let selectedName = b.filter(bu => link.lista.includes(bu.id)).map(bu => bu.title)
        setSelected(selectedName)
    },[link])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelected(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function handleUpdateLink() {
        let selectedId = b.filter(bu => selected.includes(bu.title)).map(bu => bu.id)

        //console.log(selectedId)
        firestore.collection('users/plesh/events/' + eventId + '/buttonsLink').doc(link.id)
            .set({lista: selectedId}).then(r => console.log('updated'))
    }


    async function handleRemoveLink () {
        await firestore.collection('users/plesh/events/' + eventId + '/buttonsLink').doc(link.id).delete()
    }

    return (
        <Grid container alignItems={"center"} spacing={1} sx={{py:1}}>
            <Grid item xs={2}>
                <IconButton onClick={handleRemoveLink}>
                    <RemoveCircle/>
                </IconButton>
            </Grid>
            <Grid item xs={8}>
                <FormControl sx={{width:'100%' }}>
                    <InputLabel id="demo-multiple-chip-label">Select</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selected}
                        style={{minHeight:'40px'}}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Select" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {b.map((butt) => (
                            <MenuItem
                                key={butt.id}
                                value={butt.title}
                                style={getStyles(butt, selected, theme)}
                            >
                                {butt.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={handleUpdateLink}>Save</Button>
            </Grid>

        </Grid>
    );
}
