import React, {useEffect, useRef, useState} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import PercentageVisual from "../DomandeVisual/PercentageVisual";
import SiNoVisual from "../DomandeVisual/SiNoVisual";
import QuizVisual from "../DomandeVisual/QuizVisual";
import {firestore} from "../../firebase/clientApp";
import axios from "axios";
import WordcloudVisual from "../DomandeVisual/WordcloudVisual";
import {useParams} from "react-router-dom";
import SentimentVisual from "../DomandeVisual/SentimentVisual";

export default function ControlContent({idPlaying, url,eventId}) {
    const {mode} = useParams()
    const [elementPlaying, setElementPlaying] = useState([])
    const [result, setResult] = useState({})
    const theme = useTheme()

    useEffect(() => {
        if(idPlaying!=='') {
            getElementPlaying()
            getData()
            window.clearInterval()
            let intervalId = window.setInterval(getData, 2000);
            return ()=> {
                window.clearInterval(intervalId)
            }
        }
    }, [idPlaying])

    function getElementPlaying() {
        idPlaying && firestore.collection(url + '/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
        })
    }

    function getData(){
        idPlaying!== undefined && axios.get('https://api.secondstage.app/answer/'+eventId+'/'+idPlaying).then(r => {
            setResult(r)
        })
    }


    return (
        <div style={{height:'100%'}}>
            <Box width={'100%'} component={'div'} sx={{px: 4, py: 2}} style={{
                background: elementPlaying.tipo ? theme.customColors[elementPlaying.tipo].main : '',
                borderRadius: !mode ? '50px 50px 0 0' : '0'
            }}>
                <Typography variant={"h4"}
                            style={{color: elementPlaying.tipo === 'poll' ? '#000' : ''}}>{elementPlaying.domanda}</Typography>
            </Box>
            <Box width={'100%'} height={'80%'} component={'div'} sx={{px: 2, py: 2}}>
                {(() => {
                    switch (elementPlaying.tipo) {
                        case 'clickTo':
                            return <PercentageVisual color={'#FFEB3B'} elementPlaying={elementPlaying} result={result}/>
                        case 'siNo':
                            return <SiNoVisual elementPlaying={elementPlaying} result={result}/>
                        case 'sentiment':
                            return <SentimentVisual elementPlaying={elementPlaying} result={result}/>
                        case 'quiz':
                        case 'poll':
                            return <QuizVisual elementPlaying={elementPlaying} result={result}/>
                        case 'wordcloud':
                            return <WordcloudVisual elementPlaying={elementPlaying} result={result}/>
                        default:
                            return null
                    }
                })()}
            </Box>
        </div>
    )
}

