import React from "react";
import './App.css';
import { CssBaseline, ThemeProvider} from "@mui/material";
import {pleshDark} from "./theme/pleshDark";
import {Routes, Route, useLocation} from 'react-router-dom'
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Header from "./components/Header";
import Events from "./pages/Events";
import EventDetail from "./pages/EventDetail";
import Control from "./pages/Control";
import ClassificaGiudizio from "./pages/ClassificaGiudizio";
import Dati from "./pages/Dati";


function App() {
    const location = useLocation()

    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <div className="App">

                {location.pathname.includes('/control/') ?  null: <Header/>}

                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/register' element={<Register/>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/events' element={<Events/>}/>
                    <Route path='/events/:id' element={<EventDetail/>}/>
                    <Route path='/control/:id' exact element={<Control/>}/>
                    <Route path='/control/:id/:mode' element={<Control/>}/>
                    <Route path='/control/:id/giudizio' exact element={<ClassificaGiudizio/>}/>
                    <Route path='/control/:id/dati' exact element={<Dati/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
