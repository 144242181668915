import React, {useEffect, useState} from 'react'
import {Stack} from "@mui/material";
import {useParams} from "react-router-dom";

export default function GraficoSingolo({buttonsValue, buttons}) {
    const {mode} = useParams()
    const [clicks, setClicks] = useState(1)
    const [name, setName] = useState('')

    useEffect(() => {
        if (buttonsValue.length > 0) {
            //let bu = buttonsValue.find(b => b.id === '6cvHVyyOzjgftGsnonyW')
            let bu = buttonsValue[0]
            if (bu) {
                setClicks(bu.clicks)
            }
            let butName = buttons.find(b=> b.id === bu.id)
            if(butName){
                setName(butName.title)
            }
        }
    }, [buttonsValue, buttons])
    return (
        <div style={{width: '100%', marginTop:!mode ? '1vh' : '3vh', position:'relative'}}>
            <p style={{margin:'0', fontSize: !mode ? 'inherit' : '30px'}}>{name}</p>
            {/*<div style={{
                position: 'absolute',
                height: !mode ? '8vh' : '16vh',
                left: '50%',
                borderLeft: '3px solid #f1ebe9'
            }}>

            </div>*/}
            <div style={{ borderRadius:'1.5rem',height: !mode ? '8vh' : '16vh'}}>
                <div style={{
                    transition: '1s ease',
                    background: 'linear-gradient(90deg, #3a4de4 0%, #00d8a1 100%)',
                    opacity: clicks === 0 ? '0' : '1',
                    width: 'min(' + clicks/3000*100+ '% , 100%)',
                    height: '100%',
                    borderRadius: '1rem',
                    color: '#f1ebe9'
                }}>

                </div>
            </div>
        </div>
    )
}
