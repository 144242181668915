import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {useParams} from "react-router-dom";

const SentimentVisual = ({result}) => {
    const {mode} = useParams()
    const [si, setSi] = useState(0)
    const [no, setNo] = useState(0)

    useEffect(()=>{
        setSi(result.data ? result.data.yes : 0)
        setNo(result.data ? result.data.no : 0)
    },[result])
    return(
        <div style={{width: '100%', position:'relative', marginTop:'3rem'}}>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{mb:3}}>
                <p style={{margin:'0', fontSize: '30px'}}>Sì</p>
                <p style={{margin:'0', fontSize: '30px'}}>No</p>
            </Stack>
            <div style={{opacity:'0.4',transition: '1s ease', position:'absolute', height: !mode ? '6vh' : '12vh', left:'50%', borderLeft:'7px solid #fde451', borderRadius:'1rem'}}>

            </div>
            <div style={{transition: '1s ease', position:'absolute', height: !mode ? '8vh' : '16vh', left: no+'%', borderLeft:'7px solid white', borderRadius:'1rem', bottom:!mode ? '-1.1vh' : '-2vh'}}>

            </div>
            {/*<div style={{borderRadius: '2rem', height: !mode ? '6vh' : '12vh', background: '#e2548c'}}>
                <div style={{
                    transition: '1s ease',
                    background: '#6864e6',
                    opacity: si === 0 ? '0' : '1',
                    //width: 'min(' + si+ '% , 100%)',
                    width: '50%',
                    height: '100%',
                    borderRadius: '1.6rem 0 0 1.6rem',
                    color: '#f1ebe9'
                }}>

                </div>
            </div>*/}
            <Stack direction={"row"} style={{borderRadius: '2rem'}}>
                <div style={{height: !mode ? '6vh' : '12vh', background: 'linear-gradient(90deg, #3a4de4 0%, #00d8a1 100%)', width:'100%', borderRadius: '2rem'}}/>
                {/*<div style={{
                    height: !mode ? '6vh' : '12vh',
                    background: '#e2548c',
                    width: '50%',
                    borderRadius: '0 2rem 2rem 0'
                }}/>*/}
            </Stack>
        </div>
    )
}

export default SentimentVisual
