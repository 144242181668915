import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import QueueElement from "../components/QueueElement";
import {
    Box,
    Button,
    Card,
    Container,
    Divider, FormControlLabel,
    Grid,
    IconButton,
    List, Stack, SwipeableDrawer, Switch,
    Typography
} from "@mui/material";
import AddQueueElement from "../components/AddQueueElement";
import {firestore} from "../firebase/clientApp";
import {PlayArrow, Stop} from "@mui/icons-material";
import Action from "../components/Action";
import AddButton from "../components/AddButton";
import LinkButton from "../components/LinkButton";

export default function EventDetail() {
    const {id} = useParams()
    const [queue, setQueue] = useState([])
    const [event, setEvent] = useState({})
    const [buttons, setButtons] = useState([])
    const [elementPlaying, setElementPlaying] = useState([])
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [editingButton, setEditingButton] = useState(false)
    const [buttonsLink, setButtonsLink] =useState([])
    const [refresh, setRefresh] = useState(false);
    const url = 'users/plesh/events/' + id

    useEffect(() =>{
        setEditingButton(false)
    },[event])

    function getEvent() {
        firestore.collection('users/plesh/events').doc(id).onSnapshot(s => {
            setEvent({...s.data(), id: s.id})
        })
    }

    const toggleDrawer = (newOpen) => () => {
        setDrawerOpen(newOpen);
        setEditingButton(false)
    };

    const handleSetEditingButton = () => {
        setEditingButton(!editingButton)
    }


    function getQueue() {
        firestore.collection(url + '/queue').orderBy('timestamp','asc').onSnapshot(s => {
            const items = []
            s.forEach((doc) => {
                items.push({...doc.data(), id: doc.id})
            })
            setQueue(items);
        })
    }

    function getButtons() {
        firestore.collection(url + '/buttons').onSnapshot(s => {
            const items = []
            s.forEach((doc) => {
                items.push({...doc.data(), id: doc.id})
            })
            setButtons(items);
        })
    }

    function getButtonsLinks() {
        firestore.collection(url + '/buttonsLink').onSnapshot(s => {
            const items = []
            s.forEach((doc) => {
                items.push({...doc.data(), id: doc.id})
            })
            setButtonsLink(items);
        })
    }

    function playPauseEvent() {
        firestore.collection('users/plesh/events').doc(event.id).set(
            {
                playing: !event.playing
            }, {merge: true}
        ).then(r => console.log('Updated'))
        firestore.collection('users/plesh/events').doc(event.id).set(
            {
                elementPlaying: ''
            }, {merge: true}
        ).then(r => console.log('Updated'))
    }

    function getElementPlaying() {
        firestore.collection('users/plesh/events').doc(id).onSnapshot(doc => setElementPlaying(() => doc.data().elementPlaying))
    }

    useEffect(() => {
        getQueue()
        getElementPlaying()
        getEvent()
        getButtons()
        getButtonsLinks()
    }, [])

    const handleSetRefresh = (event) => {
        setRefresh(event.target.checked);
    };

    async function handleAddElement(newElement) {
        await firestore.collection(url + '/queue').add(newElement);
    }

    async function handleAddButtonsLink(newElement) {
        await firestore.collection(url + '/buttonsLink').add(newElement);
    }

    async function handlePlayElement(i) {
        if (i === elementPlaying) {
            firestore.collection('users/plesh/events').doc(id).set(
                {
                    elementPlaying: ''
                }, {merge: true}
            ).then(r => console.log('Updated con ' + i))
        } else {
            firestore.collection('users/plesh/events').doc(id).set(
                {
                    elementPlaying: i
                }, {merge: true}
            ).then(r => console.log('Updated con ' + i))
        }
    }

    function handleRemoveElement(elementToRemove) {
        firestore.collection(url + '/queue').doc(elementToRemove).delete().then(() => {
            /**/
        }).catch((error) => {
            /**/
        });
    }

    function Queue({queue, elementPlaying, playing}) {
        return (
            queue.map((q, i) => (
                    <div key={i}>
                        <QueueElement playing={playing} elementPlaying={elementPlaying} element={q}
                                      handleRemoveElement={handleRemoveElement}
                                      handlePlayElement={handlePlayElement}/>
                        <Divider/>
                    </div>
                )
            )
        )
    }

    function Buttons({buttons, event}) {
        return (
            buttons.map((b, i) => (
                <Action button={b} event={event} key={i} editingButton={editingButton} refresh={refresh} buttonsLink={buttonsLink}/>
            ))
        )
    }

    const ButtonContainer = () => (
        <Container sx={{my: 2}}>
            <AddButton event={event} handleSetEditingButton={handleSetEditingButton}/>
            {!event.playing && <LinkButton buttons={buttons} buttonsLink={buttonsLink} handleAddButtonsLink={handleAddButtonsLink} eventId={event.id}/>}
            <FormControlLabel sx={{pb:2}} control={<Switch inputProps={{ 'aria-label': 'controlled' }} checked={refresh} onChange={handleSetRefresh}/>} label="Enable refresh"/>
            <Grid container spacing={2}>
                <Buttons buttons={buttons} event={event}/>
            </Grid>
        </Container>
    )

    const ButtonCol = () => (
        <div>
            <Typography sx={{pt: 2}} variant={'h4'}>Buttons</Typography>
            <Box sx={{display: {xs: 'block', md: 'none'}}}>
                <ButtonContainer refresh={refresh}/>
            </Box>
            <Card sx={{display: {xs: 'none', md: 'block'}}}>
                <ButtonContainer refresh={refresh}/>
            </Card>
        </div>
    )

    const QueueCol = () => (
        <div>
            <Typography sx={{pt: 2}} variant={'h4'}>Queue</Typography>
            <List>
                <Queue playing={event.playing} queue={queue} elementPlaying={elementPlaying}/>
            </List>
        </div>
    )

    const ButtonDrawer = () => (
        <SwipeableDrawer
            anchor="bottom"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            disableSwipeToOpen={false}
            sx={{display: {xs: 'flex', md: 'none'}}}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Box
                sx={{
                    px: 2,
                    pb: 2,
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <ButtonCol/>
            </Box>
        </SwipeableDrawer>
    )

    return (
        <Container>
            <Grid container sx={{py: 2}} justifyContent={"space-between"}>
                <Grid item xs={10} md={11}>
                    <Typography style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                                variant={'h3'}>{event.title}</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={playPauseEvent} style={{fontSize: '40px'}}>
                        {event.playing ? <Stop fontSize={"inherit"}/> : <PlayArrow fontSize={"inherit"}/>}
                    </IconButton>
                </Grid>
            </Grid>
            <Divider/>
            <Stack direction={'row'} justifyContent={"flex-start"} spacing={1} sx={{mt:1}}>
                <Button href={'/control/'+id} target={"_blank"}>
                    Regia
                </Button>
                <Button href={'/control/'+id+'/domanda'} target={"_blank"}>
                    Domanda
                </Button>
                <Button href={'/control/'+id+'/grafici'} target={"_blank"}>
                    Grafici
                </Button>
                <Button href={'/control/'+id+'/dati'} target={"_blank"}>
                    Dati
                </Button>
            </Stack>
            <Grid container sx={{display: {xs: 'none', md: 'flex'}}} spacing={3}>
                <Grid item xs={5}>
                    <ButtonCol/>
                </Grid>
                <Grid item xs={7}>
                    <QueueCol/>
                </Grid>
            </Grid>
            <Grid container sx={{display: {xs: 'flex', md: 'none'}, mt: 2}}>
                <Grid item xs={12}>
                    <Button onClick={() => setDrawerOpen(!drawerOpen)} variant={'contained'}>Buttons</Button>
                    <ButtonDrawer/>
                </Grid>
                <Grid item xs={12}>
                    <QueueCol/>
                </Grid>
            </Grid>
            <AddQueueElement handleAddElement={handleAddElement} event={event}/>
        </Container>
    )
}
