import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Typography} from "@mui/material";

export const Bar = ({percentage, color}) => (
    <div style={{width:'100%',height:'60%', margin:'auto'}}>
        <div
            style={{
                width: percentage + '%',
                maxWidth:'100%',
                minWidth:'0',
                borderRadius: '0 10px 10px 0',
                backgroundColor: color,
                height: '100%',
                transition: '1s ease',
            }}
        >

        </div>
    </div>
)

export default function SiNoVisual({result}) {
    const [si, setSi] = useState(0)
    const [no, setNo] = useState(0)

    useEffect(()=>{
        setSi(result.data ? result.data.yes : 0)
        setNo(result.data ? result.data.no : 0)
    },[result])

    return (
        <Container style={{width: '100%', height: '80%', textAlign: 'center', position: 'relative'}}>
            <Grid container direction={"row"} height={'100%'} sx={{my:3}}>
                <Grid item xs={1} height={'100%'}>
                    <Grid container height={'100%'}>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
                            <Typography variant={'h4'} component={"div"}>
                                Si
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
                            <Typography variant={'h4'} component={"div"}>
                                No
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11}>
                    <Box height={'100%'} width={'100%'}
                         sx={{borderBottom: '1px solid #f1ebe9', borderLeft: '1px solid #f1ebe9'}}>
                        <Grid item xs={12} height={'100%'} width={'100%'}>
                            <Grid container height={'100%'} width={'100%'}>
                                <Grid width={'100%'} item xs={12} style={{display:'flex', justifyContent:'left', alignItems:'center'}}>
                                    <Typography component={"div"} variant={'h4'} sx={{p:2, position:'absolute', color:'#f1ebe9'}}>
                                        {si}%
                                    </Typography>
                                    <Bar percentage={si} color={'#3a4de4'}/>
                                </Grid>
                                <Grid width={'100%'} item xs={12} style={{display:'flex', justifyContent:'left', alignItems:'center'}}>
                                    <Typography component={"div"} variant={'h4'} sx={{p:2, position:'absolute', color:'#f1ebe9'}}>
                                        {no}%
                                    </Typography>
                                    <Bar percentage={no} color={'#00d8a1'}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
)
}
