import React from "react";
import {Button, Grid, IconButton, Stack} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import {Edit, Refresh, RemoveCircle} from "@mui/icons-material";
import axios from "axios";

function Action({button, event, editingButton, refresh, buttonsLink}) {

    function updateButtonState() {
        let daDisabilitare = []
        buttonsLink.filter(b => b.lista.includes(button.id)).forEach(b => daDisabilitare.push(b.lista))
        daDisabilitare.length>0 ?
            daDisabilitare[0].forEach(dd =>
                firestore.collection('users/plesh/events/' + event.id + '/buttons').doc(dd).set(
                    {
                        active: !button.active
                    }, {merge: true}
                ).then(r => {/**/})
            )
            :
            firestore.collection('users/plesh/events/' + event.id + '/buttons').doc(button.id).set(
                {
                    active: !button.active
                }, {merge: true}
            ).then(r => {/**/})
    }
    async function removeButton () {
        await firestore.collection('users/plesh/events/' + event.id + '/buttons').doc(button.id).delete()
    }

    function refreshButton(){
        axios.post('https://api.secondstage.app/buttons/' + event.id + '/' + button.id + '/reset').then(r =>console.log('reset'))
    }

    return (
        <Grid item xs={6} md={4}>
            <Grid container style={{position: 'relative'}}>
                <div style={{
                    display: editingButton ? 'block' : 'none',
                    borderRadius: '4px',
                    position: "absolute",
                    width: '100%',
                    height: '100%',
                    zIndex: 10,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                }}>
                    <IconButton onClick={removeButton} style={{top:'-15px', left:'-15px'}}>
                        <RemoveCircle/>
                    </IconButton>
                </div>
                <Button onClick={updateButtonState}
                        variant={button.active ? 'contained' : 'outlined'} sx={{height: '50px', width: '100%'}}>
                    {button.title}
                </Button>
                {refresh && <Grid container justifyContent={"space-evenly"} alignItems={'center'} spacing={1}>
                    <Grid item>
                        <IconButton onClick={refreshButton}>
                            <Refresh/>
                        </IconButton>
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    )
}


export default Action