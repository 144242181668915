import React, {useEffect, useState} from "react";
import {firestore} from '../firebase/clientApp'
import Event from "../components/Event";
import AddEventForm from "../components/AddEventForm";
import 'firebase/compat/firestore';
import {Container, Stack, Typography} from "@mui/material";
import sad from '../assets/images/sad.png'
import arrow from '../assets/images/arrow.png'

export default function Events() {
    const [events, updateEvent] = useState([])

    const ref = firestore.collection('users/plesh/events')

    function getEvents() {
        ref.onSnapshot(s => {
            const items = []
            s.forEach((doc) => {
                let d=doc.data()
                items.push({...d, id: doc.id})
            })
            updateEvent(items);
        })
    }

    useEffect(() => {
        getEvents()
    }, [])

    async function handleAddEvent(newEvent) {
        const event = {title: newEvent.title, playing:false, elementPlaying:''};
        await firestore.collection("users/plesh/events").add(event);
    }

    function handleRemoveEvent(eventToRemove) {
        firestore.collection("users/plesh/events").doc(eventToRemove).delete().then(() => {
            /**/
        }).catch((error) => {
            /**/
        });
    }

    const EventList = ({events}) => (
        events.map((event) =>
            <Event key={event.title} event={event} handleRemoveEvent={handleRemoveEvent}/>
        )
    )

    return (
        <Container>
            {
                events.length > 0 ?
                    <EventList events={events}/> :
                    <Stack direction={"column"} justifyContent={"center"} alignItems={'center'} style={{height:'80vh',top:'50%', opacity:'0.35'}}>
                        <img src={sad} style={{maxWidth: '10vw'}}/>
                        <Typography sx={{p:5}} textAlign={"center"} variant={'h4'}>Hey, it's empty here!<br/>Add a new event</Typography>
                        <img src={arrow} style={{maxWidth: '30vw'}}/>
                    </Stack>
            }
            <AddEventForm handleAddEvent={handleAddEvent}/>
        </Container>
    )
}