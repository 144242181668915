import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";

export const Bar = ({percentage, color, barNum}) => (
    <div style={{width:'100%', height: '70%'}}>
        <div
            style={{
                width: percentage + '%',
                maxWidth:'100%',
                minWidth:'0',
                borderRadius: '0 10px 10px 0',
                backgroundColor: color,
                height: '100%',
                transition: '1s ease',
                opacity:'0.6'
            }}
        >
        </div>
    </div>
)

export default function QuizVisual({result, elementPlaying}) {
    const [risposte, setRisposte] = useState([])
    //const colors = ['#3772FF','#DF2935','#FDCA40','#E6E8E6','#388E3C','#3772FF','#DF2935','#FDCA40','#E6E8E6','#388E3C']
    const colors = ['#26DDA7', '#1CB8EB', '#1DF5F1', '#1DF577' ,'#E6E8E6', '#26DDA7', '#1CB8EB', '#1DF5F1', '#1DF577' ,'#E6E8E6']

    useEffect(()=> {
        setRisposte(elementPlaying.risposte)
    }, [result])

    return(
        <div style={{width: '100%', height: '90%', textAlign: 'right', position: 'relative'}}>
            <Grid container direction={"row"} height={'100%'} sx={{my:3, px:2}}>
                <Grid item xs={1} md={1} height={'100%'}>
                    <Grid container height={'100%'}>
                        {risposte.map((r,i) => (
                            <Grid key={i} item xs={12} style={{
                                display: 'flex',
                                justifyContent: 'right',
                                alignContent: 'center',
                                alignItems: 'center',
                                marginRight:'5px'
                            }}>
                                <p style={{fontSize: risposte.length+32+'px', margin:'0', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                    {/*r*/i+1}.
                                </p>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={11} md={11}>
                    <Box height={'100%'} width={'100%'}
                         sx={{borderBottom: '1px solid #f1ebe9', borderLeft: '1px solid #f1ebe9'}}>
                        <Grid item xs={12} height={'100%'} width={'100%'}>
                            <Grid container height={'100%'} width={'100%'}>
                                {risposte.map((r,i) => (
                                    <Grid width={'100%'} item xs={12}
                                          style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}} key={i}>
                                        <Typography zIndex={'99'} component={"div"} variant={'h4'}
                                                    sx={{p: 2, position: 'absolute', color: '#f1ebe9'}}>
                                            {result.data ? result.data[r] : 0}%
                                        </Typography>
                                        <Bar percentage={result.data ? result.data[r] : 0} color={colors[i]} barNum={risposte.length}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}
