import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Fab, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import makeid from "../functions/makeId";
import TimeToAnswer from "./TimeToAnswer";
import AddQueueElementForm from "./AddQueueElementForm";
import {useState} from "react";

export default function AddQueueElement({handleAddElement, event}) {
    const [open, setOpen] = React.useState(false);
    const [elementType, setElementType]= React.useState('');

    const [domanda, setDomanda] = useState('');

    const [risposte, setRisposte] = useState([])
    const [corretta, setCorretta] = useState('')

    const [soglia, setSoglia] = useState(null)

    const handleSetDomanda = (domanda) => {
        setDomanda(domanda)
    }

    const handleSetCorretta = (corretta) => {
        setCorretta(corretta)
    }

    const handleSetRisposte = (risposte) => {
        setRisposte([...risposte])
    }

    const handleSetSoglia = (soglia) => {
        setSoglia(soglia)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setElementType(event.target.value)
    }

    const handleAdd = () => {
        if (domanda!=='' && elementType!==''){

            let el = {
                domanda: domanda,
                tipo: elementType,
                timestamp: new Date().getTime()
            }
            if(elementType==='quiz'){
                el.risposte = risposte
                el.corretta = corretta
                setRisposte([])
                setCorretta('')
            } else if (elementType==='poll') {
                el.risposte = risposte
                setRisposte([])
            } else if (elementType==='clickTo'){
                el.soglia = soglia
                setSoglia(null)
            }
            setDomanda('')
            setElementType('')
            handleAddElement(el)
            handleClose();
        }

    }

    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
    };

    const AddEvent = () => (
        <Fab onClick={handleClickOpen} sx={fabStyle} aria-label={'Add'} color={"primary"}>
            <AddIcon/>
        </Fab>
    )

    return (
        <div style={{display: event.playing ? 'none' : 'block'}}>
            <AddEvent/>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Add a Queue Element</DialogTitle>
                <Box sx={{minWidth: 300, margin: 'auto'}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={elementType}
                            label="Type"
                            onChange={handleChange}
                        >
                            <MenuItem value={'quiz'}>Quiz</MenuItem>
                            <MenuItem value={'wordcloud'}>Wordcloud</MenuItem>
                            <MenuItem value={'siNo'}>Yes/no question</MenuItem>
                            <MenuItem value={'clickTo'}>Click to</MenuItem>
                            <MenuItem value={'poll'}>Poll</MenuItem>
                            <MenuItem value={'sentiment'}>Sentiment</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <AddQueueElementForm
                    type={elementType}
                    handleSetDomanda={handleSetDomanda}
                    handleSetSoglia={handleSetSoglia}
                    handleSetRisposte={handleSetRisposte}
                    handleSetCorretta={handleSetCorretta}
                    risposte={risposte}
                    corretta={corretta}
                />
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAdd}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
